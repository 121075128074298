.multi-select{
    position: relative;
    display: flex;
    width: 100%;
    appearance: none;
    flex-grow: 1;
    min-height: 40px;
    max-width: 100%;
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    font-family: var(--font-family-opensans);
    font-size: 0.875rem;
    border-radius: 4px; 
  }
  .multiprefix__value-container{
    max-height: 39px;
    font-size: 0.695rem;
  }
  .placeholder-text
  {
    font-size: 0.875rem;
    color: var(--color-text-dark);
    font-family: 'Sage-Ui';
  }
  .multiprefix__indicator-separator{
    display: none;
  }
  .multiprefix__indicator {
    color: black !important;
  }
  .multiprefix__control{
    border: 2px solid ;
    color: var(--color-text-dark);
    border-color:  var(--color-border-darker) !important;
    
  }