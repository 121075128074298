@import "../../theme/variables";

.page-wrapper {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    "header header"
    "menu   page"
    "menu   footer";
  grid-template-rows: 48px minmax(0, 1fr) 3.5rem;
  grid-template-columns: 64px minmax(0, 1fr);

  &--unscrollable {
    overflow: hidden;
  }

  &--unauthenticated {
    display: grid;
    grid-template-areas:
      "header"
      "page"
      "footer";
    grid-template-rows: 48px minmax(0, 1fr);
    grid-template-columns: 1fr;
  }
  

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    grid-template-rows: min-content minmax(0, 1fr);
    grid-template-columns: 0 minmax(0, 1fr);

    &--full-page {
      display: grid;
    }

    &--unauthenticated {
      grid-template-rows: 48px minmax(0, 1fr);
      grid-template-columns: 1fr;
    }

    &.no-scroll {
      max-height: 100vh;
      overflow: hidden;
    }
  }
}

.page-wrapper__header {
  grid-area: header;
  position: sticky;
  top: 0px;
  z-index: 10000;
  box-shadow: inset 0px -1px 0px #303030; 


  @media screen and (max-width: $breakpoint-tablet-portrait) {
    .page-wrapper--full-page & {
      display: none;
    }
  }
}

.page-wrapper__menu {
  grid-area: menu;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    .page-wrapper--full-page & {
      display: none;
    }
  }
}

.page-wrapper__page {
  grid-area: page;
  padding: 50px 35px 35px;
  background: #F4F4F4 ; 
  height: 100% !important;

  .page-wrapper--unauthenticated & {
    padding-bottom: 100px;
    background-color: var(--color-business);
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {

    &,
    .page-wrapper--unauthenticated & {
      padding: 0 20px;
    }
  }
}

.page-wrapper__footer {

  grid-area: footer;
  display: flex;
  flex-direction: column;
  align-items: center;
}