.carbon-form__select {
  position: relative;
  display: flex;
  width: 100%;

  select {
    appearance: none;
    flex-grow: 1;
    min-height: 40px;
    max-width: 100%;
    padding: 0 40px 0 8px;
    border: 1px solid var(--color-border-darker);
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    font-family: var(--font-family-opensans);
    font-size: 0.875rem;
    border-radius: 4px; 

    &:focus {
      outline: var(--focus-outline);
      box-shadow: none;
    }

    &[disabled] {
      background: var(--color-border-light) none repeat scroll 0 0;
      border-color: var(--color-border-light);
      cursor: default;
    }
  }

  .carbon-form__select-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: 40px;
    pointer-events: none;

    .icon {
      margin: auto;
    }
  }
}
