@import "../../theme/variables";

.banner {
  background-color: black;
  margin-top: 30px;
  position: relative;
  display: grid;
  grid-template-rows: 24px minmax(0, 1fr) 48px;
  grid-template-columns: 50px minmax(0, 1fr) minmax(0, 1fr);
  grid-template-areas:
    ". .       ."
    ". message ."
    ". .       .";
  overflow: hidden;
  height: inherit;
  @media screen and (max-width: $breakpoint-phone) {
    grid-template-rows: 250px auto 48px;
    grid-template-columns: 8px minmax(0, 1fr) 8px;
  }
}

.banner__background { 
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  position:relative;
  img {
    width:47%;
    height:90%;
    object-fit: cover;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;

    @media only screen and (max-width: 1080px) {
    width: 948px;
    height: 640px;
    object-fit: cover;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    }
  }
 

  @media screen and (max-width: $breakpoint-phone) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      object-position: top center;
      width: 100%;
    }
  }
}

.banner__message{
  p {
    //font-family: var(--font-family-sage-ui);
    line-height: 28.8px;
    font-size: 18px;
  }

  grid-area: message;
  display: grid;
  grid-template-rows: minmax(24px, 1fr) max-content minmax(8px, 1fr);
  grid-template-columns: clamp(24px,  125px) minmax(0, 1fr) clamp(24px, 15%, 125px);
  grid-template-areas:
    ". .       ."
    ". content ."
    ". .       .";
  background: var(--color-business);
  color: var(--color-text-white);
  button { background: #FFFFFF;}
    
  
  @media screen and (max-width: $breakpoint-phone) {
    grid-template-rows: 30px max-content 40px;
    grid-template-columns: 24px minmax(0, 1fr) 24px;
    z-index: 10;
  }

 
}

.banner__message-content {

  grid-area: content;
}

.banner__message-title { 
  font-size: 68px; 
  font-weight: 900;
  font-style: normal;
  line-height: 81.6px;
  @media screen and (max-width: $breakpoint-phone) {
    font-size: 1.75rem;
  }
}

