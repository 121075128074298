.carbon-form__filter-select {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .filterable-input {
    position: relative;

    .carbon-form__select-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      width: 40px;
      pointer-events: none;

      .icon {
        margin: auto;
      }
    }
  }

  .filterable-options {
    display: none;
  }

  .filterable-options.is-open {
    position: absolute;
    top: 40px;
    right: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    appearance: none;
    min-height: 40px;
    max-width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    border: 1px solid var(--color-border-darker);
    background: var(--color-bg-white);
    color: var(--color-text-dark);
    font-family: var(--font-family-opensans);
    font-size: 0.875rem;

    &:focus {
      outline: var(--focus-outline);
      box-shadow: none;
    }

    &[disabled] {
      background: var(--color-border-light) none repeat scroll 0 0;
      border-color: var(--color-border-light);
      cursor: default;
    }
  }
}
