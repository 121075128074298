@import "../../../../shared/theme/variables";

.new-proposal {
  display: flex;
  min-height: 100vh;
}

.new-proposal__popup {
  flex-grow: 1;
  display: grid;
  grid-template-rows: 5.625rem min-content min-content minmax(0, 1fr) min-content min-content;
  grid-template-columns: minmax(0, 1fr) 350px;
  grid-template-areas:
    "header header"
    "content client"
    "content cart-total"
    "content cart-items"
    "cgv cgv"
    "steps steps";
  column-gap: 40px;
  position: relative;
  z-index: 10;
  padding: 0 20px;
  background: #f4f4f4;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    grid-template-rows: repeat(7, min-content);
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      "header"
      "cgv"
      "steps"
      "client"
      "cart-total"
      "cart-items"
      "content";
    min-height: 100vh;
    padding: 0 10px;
  }
}

.new-proposal__header {
  grid-area: header;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: sticky;
    top: 0;
    z-index: 100;
    justify-content: space-between;
    height: 3.5rem;
    margin: 0 -10px;
    padding: 8px 20px;
    color: var(--color-text-white);
    background: var(--color-business);

    &--sepa {
      justify-content: left;
    }
  }
}

.new-proposal__title {
  margin: 0;
  font-size: 32px;
  font-weight: 700;
  font-family: 'Sage-Text';

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.new-proposal__title-mobile {
  margin: 0 0 0 10px;
  font-size: 1.25rem;
  order: 1;
  font-weight: normal;
  @media screen and (min-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.new-proposal__close {
  margin-left: auto;
  padding: 0;
  border: none;
  background: transparent;
  color: var(--color-text-light);
  outline: none;
  cursor: pointer;

  .icon {
    font-size: 1.5rem;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    order: 0;
    margin: 0;
    color: inherit;

    .icon {
      font-size: 1rem;
    }
  }
}

.new-proposal__cart-icon {
  display: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: relative;
    display: flex;
    order: 2;

    .icon {
      font-size: 1.5rem;
    }

    .badge {
      position: absolute;
      top: -3px;
      right: -3px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.25rem;
      width: 1.25rem;
      background: var(--color-success);
      border: 2px solid var(--color-border-white);
      border-radius: 50%;
      font-size: 0.6875rem;
    }

    &--sepa {
      display: none;
    }
  }
}

.new-proposal__content {
  grid-area: content;

  &--background {
    padding: 16px 24px;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    &--background {
      padding: 0;
      background: transparent;
    }
  }
}

.new-proposal__content-title {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Sage-Text';

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.new-proposal__content-sub-title {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Sage-Text';
}

.new-proposal__content-description {
  width: 100%;
  display: inline-block;
  margin: 16px 0 24px;
  font-family: var(--font-family-adelle);
  color: var(--color-text-light);
  letter-spacing: -0.2px;
  line-height: 1.625rem;
}

.new-proposal__client {
  grid-area: client;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 10px;
  background: var(--color-business);
  height: 4.9rem;
 
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: sticky;
    top: 11.5rem;
    background: var(--color-business);
    margin: 0 -10px;
    z-index: 1000;
  }
}

.new-proposal__cart-total {
  grid-area: cart-total;
  display: grid;
  position: sticky;
  top: 0;
  z-index: 100;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: sticky;
    top: 11.5rem;
    z-index: 100;
    margin: 0 -10px;
  }
}

.new-proposal__cart-total.sepa {
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    margin: 0 -10px;
    z-index: 10;
    top: 9rem;
    position: sticky;
    border-radius: 40px;
  }
}

.new-proposal__cart-items {
  grid-area: cart-items;
  display: grid;
  background: #E2E2E2;
}

.new-proposal__cgv {
  grid-area: cgv;
  position: sticky;
  bottom: 5.35rem;
  z-index: 1000;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: sticky;
    top: 3.5rem;
    height: 3.5rem;
    margin: 0 -10px;
  }
}

.new-proposal__steps {
  grid-area: steps;
  position: sticky;
  bottom: 0;
  height: 5.35rem;
  z-index: 1000;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: sticky;
    top: 3.5rem;
    z-index: 100;
    height: 8rem;
  }
}

// client card
.new-proposal__client-avatar {
   .icon{
    width: 38px;
    height: 42px;
  }
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-white);
  margin-left: 5px;

}

.new-proposal__client-content {
  display: flex;
  flex-direction: column;
}
.new-proposal__cart-radius {
border-top-left-radius: 40px;
border-top-right-radius: 40px;
}

.client-content__name {
  color: var(--color-text-white);
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
}

.client-content__description {
  color: var(--color-text-light);
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: left;
}

.new-proposal--cgv {
  .new-proposal__steps {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      top: 7rem;
    }
  }

  .new-proposal__client {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      top: 15rem;
    }
  }

  .new-proposal__cart-total {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      top: 12.5rem;
    }
  }
}
