@import "../../../theme/variables";

.quantity-picker {
  display: flex;
  align-items: center;
}

.quantity-picker__button {
  display: flex;
  position: relative;
  z-index: 10;
  padding: 6px;
  border: none;
  background: transparent;
  color: var(--color-business);

  &:hover,
  &:focus {
    color: var(--color-business-hover);
  }

  &:disabled {
    opacity: 0;
  }

  .icon {
    font-size: 1.33rem;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    .icon {
      font-size: 1rem;

      .option--selected &,
      .option--selected &:hover,
      .option--selected &:focus {
        color: var(--color-text-white);
      }
    }
  }
}

.quantity-picker__input {
  appearance: none;
  height: 1.875rem;
  width: 4.5rem;
  background: var(--color-bg-white);
  border: 1px solid var(--color-border-medium);
  color: inherit;
  text-align: center;
  font-size: 0.875rem;

  &:focus {
    outline: var(--focus-outline);
  }
  &:disabled {    
    background-color: transparent;
  }
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    margin: 0 10px;

    .option--selected & {
      background: rgba(255, 255, 255, 0.15);
      border: none;
    }
  }
}
