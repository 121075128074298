.clients-dashboard {
    display: flex;
    justify-content: space-between
}

.textDeco {
    display: flex;
    min-height: 6.375rem;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1.25rem;
}

.total__clients-count {
    text-align: center;
}

.total__clients-label {
    border: 2px solid #0073c2;
    border-radius: 6px;
    display: inline-block;
    padding: 5px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
}

.all-clients {
    margin-left: 10rem;
}

.establishments_label {
    font-size: small;
}

.total__one-sub-infos {
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
}

.establishment {
    font-size: small;
    margin-left: 53px;
}

.table-tr {
    display: flex;
    flex-direction: row;
    margin-left: 3.5rem;

}

.asterisk {
    color: red;
}

.margin-title {
    margin-top: 18px;
    font-size: 15px;
    opacity: 0;
}

.asterisk-subscription {
    justify-content: center !important;
}

.borderdash>.clients>.list>.list__row>.list__text-button button {
    text-decoration: none !important;
    color: black !important;
}

.list__withSubscription .list__total button {
    color: black;
}

.borderdash>.clients>.list>.list__header>.list__header-column {
    box-shadow: inset 0px -1px 0px #D9D9D9 !important;
}

.borderdash>.clients>.list> {
    padding: 0px 20px 0px 20px;
}