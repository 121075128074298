@import "../../../../../shared/theme/variables";

.new-proposal__cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 75px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 5px -10px 20px;
    scroll-snap-type: x proximity;

    &::before,
    &::after {
      flex-shrink: 0;
      width: 20px;
      content: "";
    }
  }
}

.new-proposal__empty {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
