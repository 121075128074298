@import "../../theme/variables";

.banner__help {
  z-index: 1;
  position: absolute;
  bottom: 40%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: var(--color-business);
  text-decoration: none;
  transform: translateX(calc(100% - 4rem));
  transition: transform ease-in 0.2s;
  border-radius: 2rem;
  &:hover,
  &:focus {
    transform: translateX(0);
    transition: transform ease-out 0.2s;
    background: white;
    border-radius: 30px;
  }

  @media screen and (max-width: $breakpoint-phone) {
    display: none;
  }
}
.banner__container {
  display: flex;
  background: white;
  border-radius: 35px;
  background-position: right;
  padding: 12px;
}
.banner__container-text{
  display: flex;
  background: #E6F2ED;
  border-radius: 35px;
}

.banner__help-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;

  .icon {
    font-size: 3.6rem;
  }
}

.banner__help-text {
  display: flex;
  align-items: center;
  margin-right: 50px;
  font-size: 0.875rem;
  border-radius: 20px;
  font-size: 18px;
}
