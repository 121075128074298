@import "../../../theme/variables";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.pagination__step {
  color: var(--color-text-gray);
  text-decoration: none;
}

.pagination__button {
  margin: 0 23px; 
  padding: 4px 16px;
  
}

.pagination__breaker {
  padding: 0 0.5em;

  &::before {
    content: "...";
  }
}

.step__default,
.step__active {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5625rem;
  width: 1.5625rem;
  margin: 0 2px;
  padding: 0;
  border-radius: 30px;
}

.pagination__button,
.step__active,
.step__default {
  font-family: var(--font-family-sage-ui);
  border: none;
  cursor: pointer;
  background: transparent;
  color: var(--color-text-dark);
  font-size: 14px;
  font-weight: 700;
  

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    font-size: 0.75rem;
  }
}

.pagination__button{
  border:2px solid;
  border-radius: 30px;
  &:not(:disabled):hover {
    color: white;
    background-color: black
    ;
  }
}
.step__default,
.step__active:focus {
  outline: none;
 
 
}
.step__active {
  background-color: var(--color-border-light);
  color: var(--color-text-dark);
}
.limit-page{
  text-align: center;
  font-weight: bold;
  color: var(--color-error) !important;
}