@import "../../../theme/variables";

.heading {
  margin-bottom: 10px;
  color: var(--color-text-dark);
}

.description {
  font-size: 0.875rem;
}

.list__header,
.list__row,
.list__row_enduser {
  --actions-count: 0;

  display: grid;
  grid-template-rows: auto;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  border: 1px solid transparent;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-template-columns: minmax(0, 1fr);
  }
}

.list__action,
.list__link {
  .has-tooltip {
    --tooltip-pos-offset: -5px;
  }
}

.list__header {
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;

  }
}


.list__row,
.list__row_enduser {
 
  background: var(--color-bg-white);
  color: var(--color-text);
  border-bottom-color: var(--color-border-medium);

  &:hover {
    background: var(--color-bg-selected);
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    border: none;
    margin: 0 0 20px;

    &,
    &:hover {
      background: transparent;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list__row.selected,
.list__row_enduser.selected {
  background: var(--color-bg-selected);

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    background: transparent;
  }
}

.list__row.checkout {
  background: #EFFFF4;

  &:hover {
    background: #CCFFDC;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    background: transparent;
  }
}

.list__row-column_coche-validation {
  font-size: 16px !important;
}

.list__header-column,
.list__row-column {
  display: flex;
  align-items: center;
  padding: 8px;
  word-break: break-word;
  min-height: 2.5rem;
  font-size: 16px ;//0.875rem;
  font-weight: 400;
  line-height: 1.3rem;
  


  a,
  a:visited,
  button {
    --color: var(--color-business);

    display: flex;
    margin: -8px;
    padding: 11px;
    color: var(--color);
  }

  button {
    border: none;
    background: transparent;
  }

  a:hover,
  a:focus,
  button:hover,
  button:focus {
    --color: var(--color-business-hover);
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    min-height: initial;
    padding: 0;
    word-break: initial;

    a,
    button {
      // position: relative;
      // top: -5px;
      margin: 0;
      padding: 8px;
    }

    .icon {
      font-size: 1.25rem;
    }
  }
}

.list__header-column {
  font-weight: bold;
  color: black; 
  box-shadow: inset 0px -2px 0px #00D639;

}

.list__name:not(.list__header-column) { 
  color: #474747;
  font-size: 16px;
  font-weight: 400;

}

.list__actions {
  justify-content: flex-end;
  padding: 0;

  a,
  a:visited,
  button {
    margin: 0;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    margin-right: -8px;
  }
}

.list__text-button {
  button {
    font-size: inherit;
    line-height: inherit;
    font-family: var(--font-family-sage-text);
    text-decoration: underline;
    color: #007E45;
    

  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    button {
      position: static;
      padding: 0;
    }
  }
}

.coche-validation {
  font-size: 2.125rem;
}