.interlocutor-form {
  display: flex;
  flex-direction: column;
}

.interlocutor-form__item {
  margin: 0.75em 0;
  display: flex;
  flex-direction: column;
}

.interlocutor-form__label {
  margin: 0.5em 0;
}

.interlocutor-form__select {
  width: 100%;
  background: #fff;
  border: 1px solid #668592;
  box-sizing: border-box;
  margin-bottom: 1em;
  min-height: 40px;
  padding-left: 11px;
  padding-right: 11px;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: left;
}

.interlocutor-form__mandatory {
  font-weight: normal;
  font-size: 0.75rem;
}
