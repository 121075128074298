.help-menu {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  width: 24rem;
  background: var(--color-bg-white);
  box-shadow: 0 20px 40px 0 rgba(0, 20, 29, 0.1), 0 10px 20px 0 rgba(0, 20, 29, 0.2);
  font-size: 0.875rem;
  z-index: 1000;

  &.is-open {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

.help-menu__title {
  margin: 0;
  padding: 16px 24px;
  box-shadow: inset 0 -1px 0 0 #d9e0e4;
  background: #e6ebed;
  font-size: 1rem;
}

.help-menu__link {
  display: flex;
  align-items: center;
  height: 3rem;
  width: 100%;
  border: none;
  background: none;
  font-size: inherit;

  &,
  &:visited {
    padding: 0 24px;
    color: inherit;
    text-decoration: none;
    font-weight: 500;
  }

  &:hover {
    background: var(--color-business);
    color: var(--color-text-white);
  }

  &--separator {
    border-top: 1px solid var(--color-border-dark);
  }
}

.help-menu__link-icon {
  display: flex;
  margin-right: 24px;
}
