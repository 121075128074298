@import "../../../../shared/theme/variables";

[data-component="card"] {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-adelle);
  flex: 0 0 22rem;
  margin: 25px 15px ;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    max-width: calc(100vw - 60px);
    margin: 0 10px 25px !important;
    scroll-snap-align: center;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
.offerslist__cards-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 3rem;
  margin-right: auto;
  width: 100%;
  align-content: center;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    margin-top: 25px;
    flex-direction: column;
    width: 100%;
  }
}

.offers__titles{
    text-align: center;
    margin-bottom: inherit;
    margin-top: 26px;
}

.offers__paragraphs{
    text-align: center;
    margin-bottom: inherit;
    margin-top: inherit;
}

.card__header_green {
  color: black;
  min-height: 8.5rem;
  margin: 0 -24px;
  padding: 12px 24px 20px;
  background: var(--color-text-white);
  border-radius: 40px;
  width: -webkit-fill-available;
}

.card__offer {
  margin-bottom: 20px;
  font-size: 0.75rem;
  letter-spacing: 0.15px;
}

.card__offer-name {
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: normal;
  letter-spacing: 0.3px;
}

.card__offer-price {
  margin: 10px 0 2px;
  font-size: 1.125rem;
  line-height: 1;
}

.offer-price__amount-euros {
  margin-right: 0.125ch;
  font-size: 3rem;
  font-weight: 900;
}

.offer-price__amount-cents {
  font-size: 1.25rem;
}

.card__offer-info {
  margin-bottom: 25px;
  color: var(--color-text-light);
}

.card__offer-options {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &:last-child {
    margin-bottom: 10px;
  }

  li {
    margin: 10px 0;
    display: flex;
    padding-top: 0.2em;
  }

  .icon {
    position: relative;
    top: 0.1em;
    margin: 0 15px 0 0;
    color: var(--business);
    font-size: 1rem;
  }
}

