.compatibility-page {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.compatibility-page__title {
  font-size: 1.5rem;
}

.compatibility-page__msg {
  font-size: 1.125rem;
  line-height: 1.3;
}

.compatibility-page__title,
.compatibility-page__msg {
  margin-bottom: 1em;
}
