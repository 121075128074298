// --------------------------
// Adelle Sans Sage
// normal: bold, regular, light
// italic: bold, regular, light
// --------------------------

 @font-face {

   font-family: "Sage-Ui";
   font-style: normal;
   font-weight: 400;
   src: url("../assets/fonts/sage-ui/Sage_UI-Regular.woff") format("truetype");
 }


@font-face {

  font-family: "Sage-Text";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/sage-text/Sage_Text-Regular.woff") format("truetype");
}
@font-face {

  font-family: "Sage-Headline";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/sage-headline/Sage_Headline-Black.woff") format("truetype");
}


@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/adelle-sans/AdelleSansSage-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/adelle-sans/AdelleSansSage.ttf") format("truetype");
}

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/adelle-sans/AdelleSansSage-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/fonts/adelle-sans/AdelleSansSage-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: italic;
  font-weight: normal;
  src: url("../assets/fonts/adelle-sans/AdelleSansSage-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Adelle-Sans-Sage";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/adelle-sans/AdelleSansSage-LightItalic.ttf") format("truetype");
}



@font-face {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/open-sans/OpenSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/open-sans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/open-sans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/open-sans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/open-sans/OpenSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: italic;
  font-weight: 900;
  src: url("../assets/fonts/open-sans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/fonts/open-sans/OpenSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/open-sans/OpenSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../assets/fonts/open-sans/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Open-Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/open-sans/OpenSans-LightItalic.ttf") format("truetype");
}