@import "../../../../shared/theme/variables";

.subscription-message__description {
  font-weight: normal;
}
.subscription-list-page {
  .heading,
  .description,
  .list__select-wrapper,
  .popover-button > button {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      display: none;
    }

    .establishment-filter {
      width: 15rem;
    }
  }

  .list__header-actions {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      margin: 0;
    }
  }

  .list {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      padding: 24px 0 24px;
    }
  }

  .list__row {
    .list__reference {
      font-weight: bold;
    }
  }
  .list__header_big {
    display: grid;
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      display: none;
    }
  }
  
  .list__header_big,
  .list__row {
    grid-template-columns:
    minmax(0, 2fr)
    minmax(0, 2fr)
    minmax(0, 3fr)
    minmax(0, 2fr)
    minmax(0, 3fr)
    minmax(0, 3fr)
    minmax(0, 2fr)
    minmax(0, 3fr)
    minmax(0, 4fr)

      calc(var(--actions-count) * 40px);
    .list__status {
      justify-content: center;
    }
   
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      grid-template-rows: repeat(4, min-content);
      grid-template-columns: minmax(0, 1fr) max-content;
      grid-template-areas:
        "status  actions"
        "name    name"
        "code    code"
        "service date";
      align-items: flex-start;
      color: var(--color-text-light);

      .list__reference {
        display: none;
      }

      .list__status {
        grid-area: status;
        justify-content: flex-start;
      }

      .list__client-name {
        grid-area: name;
        font-size: 1rem;
        font-weight: normal;
        color: var(--color-text);
      }

      .list-code {
        grid-area: code;
      }

      .list__service {
        grid-area: service;
      }

      .list__date {
        grid-area: date;
        justify-content: flex-end;
        font-size: 0.625rem;
      }

      .list__actions {
        grid-area: actions;
      }
    }
  }
}
