@import "../../../../shared/theme/variables";

// --------------------------
// EMPTY CART
// --------------------------
.cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 15px;
  text-align: center;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.cart-empty__image {
  width: 185px;
  margin-bottom: 25px;
}

.cart-empty__title {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 32px;
  font-family: 'Sage-Text';
}
// end EMPTY CART

// --------------------------
// CART PRODUCT LIST
// --------------------------
.cart__products-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.cart__products-list.sepa {
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: unset;
    margin: 0 -10px;
  }
}

// --------------------------
// CART PRODUCT
// --------------------------
.cart-product {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: minmax(0, 1fr) min-content;
  grid-template-areas:
    "offer   offer"
    "options options";
  align-items: center;
  padding: 10px 15px;
  background: white;
  color: black ;

  &.config:not(:last-child) {
    margin-bottom: 20px;
  }
}

// --------------------------
// CART PRODUCT PRINCIPAL OFFER
// --------------------------

.cart-product__principal-offer {
  grid-area: offer;
}

.cart-product__principal-offer-name,
.cart-product__principal-offer-price,
.cart-product__principal-offer-description {
  margin-bottom: 0.25rem;
  line-height: 1.2;
  letter-spacing: 0.15px;
}

.cart-product__principal-offer-name {
  font-size: 1rem;
  font-weight: normal;
}

.cart-product__principal-offer-price {
  font-size: 0.875rem;
  text-align: end;
}

.cart-product__principal-offer-description {
  font-size: 0.75rem;
  opacity: 0.6;
}

// --------------------------
// CART PRODUCT SECONDARY OFFER
// --------------------------

.cart-product__secondary-offer {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: 5fr 1fr 1fr;
  grid-template-areas:
    "delete delete delete"
    "name   quantity price"
    "description description description ";
  margin-bottom: 2px;
}

.cart-product__secondary-offer__name,
.cart-product__secondary-offer__description {
  font-family: var(--font-family-opensans);
  margin: 0;
}

.cart-product__secondary-offer__name {
  grid-area: name;
  margin-bottom: 0.25rem;
  line-height: 1.2;
  letter-spacing: 0.15px;
  font-size:16px; 
  font-weight: bold 
}

.cart-product__secondary-offer__description {
  grid-area: description;
  font-size: 0.75rem;
  opacity: 0.6;
}

.cart-product__secondary-offer__delete {
  grid-area: delete;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
}

.cart-product__secondary-offer__delete-pp {
  grid-area: delete;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 8px;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
}
// --------------------------
// PRODUCTS CONFIG
// --------------------------
.cart-product__options {
  grid-area: options;
  position: relative;
  margin: 6px 0 0;
  padding: 10px 0 0;
  list-style: none;

  &::before {
    position: absolute;
    top: 0;
    left: 16px;
    right: 16px;
    height: 1px;
    background: rgba(255, 255, 255, 0.15);
    content: "";
  }
}

.cart-product__option {
  display: grid;
  grid-template-columns: 1fr 3.125rem 7.25rem;
  grid-template-rows: max-content;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  letter-spacing: 0.13px;
  line-height: 1.2;
}

.cart-product__option-label {
  flex-grow: 1;
  font-size: 12px; 
}

.cart-product__option-quantity {
  width: 3rem;
  text-align: right;
}
.cart-product__option-quantity-pp {
  text-align: right;
  grid-area: quantity;
  font-size: 0.875rem;
}
.cart-product__option-price {
  width: 7.25rem;
  text-align: right;
}
.cart-product__option-price-pp {
  width: 7.25rem;
  text-align: right;
  grid-area: price;
  font-size: 0.875rem;
}