.button {
  border: 2px solid transparent;
  box-sizing: border-box;
  display: inline-flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 40px;


  span {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.button.medium {
  font-size: 0.875rem;
  //font-weight: 600;
  height: 40px;
  padding: 1px 24px;
  display: flex; 
  flex-direction: row-reverse;
  font-family: var(--font-family-sage-ui);
  font-size: 16px;
  font-weight: 700;

}

.button.primary {
  background:  #007E45;
  color: white;

  &:not(:disabled):hover {
    background:  #006738;
    color: white;
  }

  margin-right: 1%;
}

.button.secondary {
  border: 2px solid  #007E45;
  background: transparent;
  color:  #007E45;

  &:not(:disabled):hover {
    border: 2px solid  #007E45;
    background:  #007E45;
    color: white;
  }
}

.button.offersfree {
  border: 2px solid #007E45;
  ;
  background: transparent;
  color: #007E45;
  ;

  &:not(:disabled):hover {
    border: 2px solid #007E45;
    ;
    background: #007E45;
    ;
    color: white;
  }


}
.popover-button .button, .simple-button .button 
{
  border: 2px solid var(--color-business);
  background: transparent;
  color: var(--color-business);
  font-size: 14px;
  font-weight: 700;
  &:not(:disabled):hover {
    border: 2px solid var(--color-business-hover);
    background: var(--color-business-hover);
    color: white;
  }
 
  
}
.main-header-mobile .button.primary{ 
  background: transparent;
  color: white;
  &:not(:disabled):hover {
    border: 2px solid white;
    background: var(--color-business-hover);
    color: white;
  }
}
.button.medium.tertiary {
  background: transparent;
  color: white;
  border-color: white;
  font-family: 'Sage-Text';
  font-size: 14px;
  font-weight: 400;

  &:not(:disabled):hover {
    color: black;
    background:white;
  }

}
 .step-navigation__order.button.medium.tertiary,.step-navigation__save.button.medium.tertiary{
   color: #008146;
   background: transparent;
   font-family: var(--font-family-sage-ui);
   font-weight: 700;
   font-size: 16px;
   margin-inline: 15px;
   &:not(:disabled):hover {
     color: #006738;
     ;
   }
 }
.step-navigation__previous.button.medium.tertiary{
  background: transparent;
  color: #008146;
  font-family: var(--font-family-sage-ui);
  font-size: 16px;
  font-weight: 700;
  &:not(:disabled):hover {
    color: #006738;
    ;
  }
   
}
 .select-interlocutor.full .select-interlocutor__button{ 
  border-radius: unset;
  
}

.button.dashed {
  border: 2px dashed #007E45; 
  background: transparent;
  color: #007E45;

  &:not(:disabled):hover {
    border: 2px dashed #007E45;
    background: #007E45;
    color: white;
  }
}

.button:disabled {
  background: green; 
  color: white ;
  opacity: 0.5;
}