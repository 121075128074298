@import "../../../../shared/theme/variables";

.cart-total {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "title       amount"
    "description description";
  margin: 0;
  padding: 15px;
  font-family: var(--font-family-adelle);
  color: var(--color-text-white);
  background: black;
}

.cart-subtotal {
  position: relative;
  padding: 6px 15px;

  &:first-child {
    padding-top: 12px;
  }

  &:nth-child(2) {
    padding-bottom: 12px;
  }

  &:nth-child(2)::after {
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    height: 1px;
    background: rgba(255, 255, 255, 0.15);
    content: "";
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.cart-subtotal.sepa {
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: grid;
  }
}

.cart-total__title {
  grid-area: title;
  font-weight: bold;
  font-size: 1.5rem;

  .cart-subtotal & {
    font-size: 1.25rem;
    font-weight: normal;
    
  }
}
.cart-total__title.sepa{
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    font-size: 1rem;
  }
}

.cart-subtotal-ttc .cart-total__title.sepa{
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    font-size: 1.2rem;
  }
}

.cart-total__amount {
  width: 12rem;
  grid-area: amount;
  font-weight: normal;
  text-align: right;
}

.cart-total__amount-euros {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: 'Sage-Ui';
  

  .cart-subtotal & {
    font-size: 1.375rem;
    font-weight: normal;
  }
}

.cart-total__amount-euros.sepa{
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    font-size: 1rem;
  }
}

.cart-subtotal-ttc .cart-total__amount-euros.sepa{
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    font-size: 1.2rem;
  }
}

.cart-total__amount-cents {
  font-size: 1.125rem;

  .cart-subtotal & {
    font-size: 0.875rem;
  }
}

.cart-total__amount-cents.sepa{
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    font-size: 0.775rem;
  }
}

.cart-subtotal-ttc .cart-total__amount-cents.sepa{
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    font-size: 0.9rem;
  }
}

.cart-total__mention,
.cart-total__description {
  grid-area: description;
  font-weight: normal;
  opacity: 0.6;
}

.cart-total__mention {
  font-size: 0.625rem;
  text-align: right;
}

.cart-total__description {
  font-size: 0.75rem;
}
