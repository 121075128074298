@import "../../../../../shared/theme/variables";

.options-lists-wrapper {
  margin-bottom: 40px;
}

.options-list {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    margin: 0 -10px;
  }
}

.options-list__category {
  margin: 2rem 0 1.25rem;
  color: var(--color-text-light);
  font-size: 0.75rem;
  text-transform: uppercase;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.options-list__choose {
  margin-bottom: 0.75rem;
  color: var(--color-text-light);
  font-size: 0.75rem;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}
