// --------------------------
// Form inputs replacements
// --------------------------

// Label
.carbon-form__label-wrapper {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 6px;
}

// Label tooltip indicator
.carbon-form__label-helper {
  position: relative;
  display: inline-flex;
  margin: 0 0.5em;

  .icon {
    color: var(--color-business);
  }
}

// Radio button
.carbon-form__radio {
  position: relative;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    height: 16px;
    width: 16px;
    opacity: 0;
  }

  .carbon-form__radio-icon {
    display: flex;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid rgb(102, 133, 146);
    background: #fff;
    margin-right: 8px;


    &::after {
      display: none;
      height: 8px;
      width: 8px;
      margin: auto;
      background: var(--color-text);
      border-radius: 50%;
      content: "";
    }
  }

  input:checked + .carbon-form__radio-icon::after {
    display: block;
  }
}

// Checkbox
.carbon-form__checkbox {
  position: relative;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    height: 16px;
    width: 16px;
    opacity: 0;
  }

  .carbon-form__checkbox-icon {
    display: flex;
    margin-right: 8px;
    height: 16px;
    width: 16px;
    border: 1px solid rgb(102, 133, 146);
    background: #fff;
  }

  input:checked + .carbon-form__checkbox-icon {
    background: no-repeat url(../assets/icons/check.svg);
  }
}

// Error message
.carbon-form__error {
  margin-top: 0.25em;
  color: var(--color-error);
  font-size: 0.875rem;
}

// --------------------------
// Tooltip replacement
// --------------------------
.carbon-tooltip-wrapper {
  display: grid;
  grid-template-columns: max-content;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  opacity: 0;
  transition: opacity ease-in-out 0.2s;
  pointer-events: none;

  .carbon-form__label-helper:hover &,
  .carbon-form__label-helper:focus & {
    opacity: 1;
  }
}

.carbon-tooltip {
  position: relative;
  z-index: 2000;
  max-width: 300px;
}

.carbon-tooltip__inner {
  display: inline-block;
  padding: 12px 16px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  font-weight: 700;
}

.carbon-tooltip__arrow {
  position: absolute;
  left: calc(50% - 7px);
  bottom: 0;

  &::before {
    position: absolute;
    width: 0;
    height: 0;
    border-color: rgb(0, 0, 0) transparent currentcolor;
    border-style: solid solid none;
    border-width: 8px 7px medium;
    content: "";
  }
}
