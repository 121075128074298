@import "../../../theme/variables";

.full-page-header {
  display: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10000;
    min-height: 3.5rem;
    margin: 0 -20px;
    padding: 8px 10px;
    background: var(--color-business);
    color: var(--color-text-white);
  }
}

.full-page-header__title {
  margin: 0 auto 0 20px;
  font-family: var(--font-family-adelle);
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: 0.25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.full-page-header__button {
  padding: 8px;
  border: none;
  background: transparent;
  color: inherit;
  line-height: 1;
  cursor: pointer;

  .icon {
    font-size: 1.25rem;
  }
}
