@import "theme/normalize";
@import "theme/variables";
@import "theme/fonts";
@import "theme/carbon-replace";
@import "theme/tooltips";

:root {
  // font families
  --font-family-sage-ui: "Sage-Ui", Roboto, sans-serif;
  --font-family-sage-headline: "Sage-Headline", Roboto, sans-serif;
  --font-family-sage-text: "Sage-Text", Roboto, sans-serif;
  // Color palette (not to be used directly)
  --black-100: #000;
  --black-90: rgba(0, 0, 0, 0.9);
  --black-85: rgba(0, 0, 0, 0.85);
  --black-55: rgba(0, 0, 0, 0.55);
  --black-40: rgba(0, 0, 0, 0.4);
  --black-30: rgba(0, 0, 0, 0.3);
  --dark-gray: #668592;
  --gray: #ccd6db;
  --gray-2: #d6d6d6;
  --gray-3: #e5eaec;
  --gray-4: #f2f4f5;
  --white: #fff;
  --blue-light: #e6f1fa;
  --blue-38: #0073c2;
  --blue-30: #005c9a;
  --dark-blue: #004472;
  --green: #00b000;
  --orange: #e96400;
  --red: #c7384f;
  --green-light:#D1FDE0;

  // Colors
  --color-text-dark: var(--black-90);
  --color-text: var(--black-85);
  --color-text-light: var(--black-55);
  --color-text-lighter: var(--black-30);
  --color-text-gray: var(--dark-gray);
  --color-text-white: var(--white);
  --color-business: var(--black-100);
  --color-business-hover: var(--black-100);
  --color-success: var(--green);
  --color-warning: var(--orange);
  --color-error: var(--red);

  // Backgrounds
  --color-bg-white: var(--white);
  --color-bg-grey: var(--gray-4);
  --color-bg-dark-blue: var(--dark-blue);
  --color-bg-black: var(--black-100);
  --color-bg-selected: var(--green-light);
  // Borders
  --color-border-darker: var(--dark-gray);
  --color-border-dark: var(--gray);
  --color-border-medium: var(--gray-2);
  --color-border-light: var(--gray-3);
  --color-border-white: var(--white);
  --color-overlay: var(--black-40);

  // Other custom properties
  --focus-outline: solid 3px #ffb500 !important;
}

body {
 
  font-family: "Sage-Text", Roboto, sans-serif;
  font-family: var(--font-family-sage-text); 
  color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
 
font-family: "Sage-Headline", Roboto, sans-serif;
font-family: var("--font-family-sage-headline");
}
