.carbon-form__textarea {
  width: 100%;

  textarea {
    width: 100%;
    padding: 8px 40px 8px 11px;
    background: var(--color-bg-white);
    border: 1px solid var(--color-border-darker);
    color: var(-color-text-dark);
    font-family: var(--font-family-opensans);
    font-size: 0.875rem;
    resize: vertical;
    border-radius: 4px;

    &:focus {
      outline: var(--focus-outline);
    }

    &[disabled] {
      background: var(--color-border-light) none repeat scroll 0 0;
      border-color: var(--color-border-light);
      cursor: default;
    }
  }
}
