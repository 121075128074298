
.popupNews-menu{
display: none;
position: absolute;
width: 39rem;
padding: 24px 20px 10px 20px;
background: var(--color-bg-white);
box-shadow: 0 20px 40px 0 rgba(0, 20, 29, 0.1), 0 10px 20px 0 rgba(0, 20, 29, 0.2);
font-size: 1.2rem;
z-index: 1000;
min-height: max-content;
transform: translate3d(-514px, 65px, 0);

&.is-open {
  display: block;
}

.full-page-header {
  display: none;
  margin: 0 -16px 24px;
}


}