.config-button {
  border: 2px solid var(--color-business);
  background: transparent;
  color: var(--color-business);
  font-weight: 700;
  font-size: 0.875rem;
  height: 40px;
  padding: 1px 24px;
  display: flex;
  flex-direction: row-reverse;
  font-family: var(--font-family-sage-ui);
  font-weight: 700;
  box-sizing: border-box;
  display: inline-flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 40px;

  &:not(:disabled):hover {
    border: 2px solid var(--color-business);
    background: var(--color-business);
    color: white;
  }
}

.form-config {
  font-family: var(--font-family-sage-text);
  color: var(--color-text);
}

.input-config {
  width: 100%;
  min-height: 40px;
  background: var(--white);
  border: 1px solid var(--color-border-darker);
  flex-grow: 1;
  font-family: var(--font-family-opensans);
  font-size: 0.875rem;
  outline: none;
  border-radius: 4px;
  padding: 7px;
  margin: 7px;
}

.config-error {
  margin: 0.5em 0em 0.5em 0em;
  color: var(--color-error) !important;
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: 19px;
}
.list__checkbox>.carbon-form__checkbox {
  input {
    opacity: inherit !important;
  }

}
.Collapsible__trigger {
  border-radius: 20px 20px 0 0 !important;
  &:after {
    border-radius: 40px;
  }
}

.offers-config{
  margin-top: 1rem;
}