// --------------------------
// Links and buttons tooltip
// Added when an element has the 'has-tooltip' class AND the 'aria-label' attribute
//
// Default position: top center
// Vertical position classes: 'top', 'bottom'
// Horizontal position classes: 'left', 'center', 'right';
//
// Tooltip vertical position can be adjusted by setting the '--tooltip-pos-offset' property
//   globally or for specific elements (default: 0px);
// --------------------------
.has-tooltip {
  --tooltip-pos-offset: 0px;

  position: relative;
}

.has-tooltip[aria-label] {
  &:hover:after,
  &:hover:before {
    position: absolute;
    z-index: 10000;
    display: grid;
    grid-template-columns: max-content;
    transform: translateX(-50%);
  }

  // Tooltip text
  &:hover:after {
    padding: 8px;
    background: var(--black-100);
    color: var(--color-text-white);
    font-family: var(--font-family-opensans);
    font-size: 0.875rem;
    font-weight: bold;
    content: attr(aria-label);
    line-height: 1;
  }

  // tooltip "arrow"
  &:hover:before {
    border: solid transparent;
    content: "";
  }
}

// Tooltip vertical position
.has-tooltip[aria-label],
.tooltip-top[aria-label] {
  &:hover:after {
    bottom: calc(100% + 10px + var(--tooltip-pos-offset));
  }

  &:hover:before {
    bottom: calc(100% + var(--tooltip-pos-offset));
    border-width: 10px 13px 0 13px;
    border-top-color: var(--black-100);
  }
}

.tooltip-bottom[aria-label] {
  &:hover:after {
    top: calc(100% + 10px + var(--tooltip-pos-offset));
    bottom: unset;
  }

  &:hover:before {
    top: calc(100% + var(--tooltip-pos-offset));
    bottom: unset;
    border-width: 0 13px 10px 13px;
    border-bottom-color: var(--black-100);
  }
}

// Tooltip horizontal position
.has-tooltip[aria-label],
.tooltip-center[aria-label] {
  &:hover:after,
  &:hover:before {
    left: 50%;
  }
}

.tooltip-left[aria-label]:hover::after {
  left: -10px;
  transform: none;
}

.tooltip-right[aria-label]:hover::after {
  left: unset;
  right: -10px;
  transform: none;
}
