@import "../../../../shared/theme/variables";

.new-client {
  display: flex;
  min-height: 100vh;
}

// --------------------------
// Fake popin
// --------------------------
.new-client__popup {
  flex-grow: 1;
  display: grid;
  grid-template-rows: 5.625rem minmax(0, 1fr) min-content;
  grid-template-columns: minmax(0, 1fr) max-content;
  grid-template-areas:
    "header header"
    "content cart"
    "footer  footer";
  column-gap: 40px;
  position: relative;
  z-index: 10;
  padding: 0 20px;
  background: var(--color-bg-grey);
  font-size: 0.875rem;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    grid-template-rows: repeat(2, min-content) minmax(0, 1fr) min-content;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      "header"
      "content"
      "cart"
      "footer";
    min-height: 100vh;
    padding: 0;
    background: var(--color-bg-white);
  }
}
.new-client__radius {
border-top-left-radius: 40px;
border-top-right-radius: 40px;
}
// --------------------------
// Fake popin header
// --------------------------
.new-client__header {
  grid-area: header;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: sticky;
    top: 0;
    z-index: 100;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 2.5rem minmax(0, 1fr) 2.5rem;
    column-gap: 10px;
    min-height: 3.5rem;
    margin: 0 -10px;
    padding: 8px;
    color: var(--color-text-white);
    background: var(--color-business);
  }
}

.new-client__title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    order: 1;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.new-client__close {
  margin-left: auto;
  padding: 0;
  border: none;
  background: transparent;
  color: var(--color-text-light);
  outline: none;
  cursor: pointer;

  .icon {
    font-size: 1.5rem;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    order: 0;
    margin: 0;
    color: inherit;

    .icon {
      font-size: 1rem;
    }
  }
}

// --------------------------
// Fake popin content
// --------------------------
.new-client__content {
  grid-area: content;

  &--background {
    padding: 16px 24px;
    background: var(--color-bg-white);
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    &--background {
      padding: 0;
      background: transparent;
    }
  }
}

// --------------------------
// User cart
// --------------------------
.new-client__cart {
  grid-area: cart;
  display: grid;
  //background: var(--color-bg-white);
  width: 350px;
  align-self: flex-start;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    width: 100%;
  }
}

.new-client__client {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 10px;
  background: var(--color-business);
  height: 4.9rem;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: sticky;
    top: 11.5rem;
    background: var(--color-business);
    margin: 0 -10px;
    z-index: 1000;
  }
}

.new-client__client-avatar {
  .icon{
    width: 38px;
    height: 42px;
  }
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-white);
}

.new-client__client-content {
  display: flex;
  flex-direction: column;
}

// --------------------------
// Tabs
// --------------------------
.new-client__tabs {
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: max-content minmax(0, 1fr);
  grid-template-areas:
    "tablist tabpanel"
    ".       tabpanel";

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      "tablist"
      "tabpanel";
    margin: 0;
  }
}

.new-client__tablist {
  grid-area: tablist;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  width: 100vw;
  max-width: 12.5rem;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    flex-direction: row;
    overflow-x: auto;
    background: var(--color-bg-white);
    // scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 0;
      background: transparent;
    }
  }
}

.new-client__tab {
  margin: 0 -2px 10px 0;
  padding: 8px 16px;
  appearance: none;
  border: none;
  background: transparent;
  color: var(--color-text-light);
  font-family: var(--font-family-adelle);
  font-size: inherit;
  font-weight: bold;
  border-right: 2px solid transparent;
  text-align: right;

  &:hover {
    color: var(--color-text);
  }

  &--current {
    color: var(--color-text);
    border-color: var(--color-business);
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: relative;
    padding: 15px;
    margin: 0;
    border: none;
    font-family: var(--font-family-opensans);
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    white-space: nowrap;
    // scroll-snap-align: center;
    color: var(--color-text-light);

    &--current {
      color: var(--color-business);
    }

    &--current::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      z-index: 1000;
      background: var(--color-business);
      content: "";
    }
  }
}

.new-client__tabpanel {
  grid-area: tabpanel;
  padding: 24px;
  border-left: 2px solid var(--color-border-light);
  background: var(--color-bg-white);
}

// --------------------------
// Footer / actions
// --------------------------
.new-client__footer {
  grid-area: footer;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  margin: 48px -20px 0;
  padding: 16px;
  border-top: 2px solid var(--color-border-light);
  background: var(--color-bg-white);
}

.new-client__subtitle {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}
.new-client-tabpanel__header {
  display: flex;
}
.tabpanel-header__title {
  margin-right: auto;
}

.new-client__date {
  margin-bottom: 0.75em;
  font-weight: bold;
}

.new-client__date_top {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  font-weight: bold;
}

.new-client__error{
  margin: 0.5em 0em 0.5em 0em;
  color: var(--color-error);
  font-size: 0.75rem;
  font-weight: bold;
}
.tabpanel-header__pill{
  width: 400px;
}