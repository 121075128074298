.new-client__section{
  display: flex;
  flex-direction: column;
}

.section__header{
  margin: 4em 0em 2em 0em;
  color: var(--color-text-light);
  font-size: 1rem;
  font-weight: bold;

}

.new-client-form__item{
  margin: 0.75em 0;
  display: flex;
  flex-direction: column;
}

.new-client-form__label{
  margin: 0.5em 0;
  font-weight: normal;
}

.new-client-form__button{
  align-self: flex-end;
}

.new-client__message{
  margin : 0.5em 0em 0.5em 0em;
  color: var(--color-warning);
  font-size : 0.75rem;
  font-weight: bold;
}