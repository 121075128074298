.carbon-form__input {
  position: relative;
  display: flex;
  width: 100%;

  input {
    width: 100%;
    min-height: 40px;
    padding: 0 40px 0 11px;
    background: var(--white);
    border: 1px solid var(--color-border-darker);
    color: var(-color-text-dark);
    flex-grow: 1;
    font-family: var(--font-family-opensans);
    font-size: 0.875rem;
    outline: none;
    border-radius: 4px; 

    &:focus {
      outline: var(--focus-outline);
    }

    &[disabled] {
      background: var(--color-border-light) none repeat scroll 0 0;
      border-color: var(--color-border-light);
      cursor: default;
    }

    &:invalid,
    &.is-invalid {
      border-color: var(--color-error) !important;
      box-shadow: var(--color-error) 1px 1px 0 inset, var(--color-error) -1px -1px 0 inset;
    }
  }

  .carbon-form__input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: 40px;
    pointer-events: none;

    .icon {
      margin: auto;
      color: var(--color-error);
    }
  }

  .carbon-form__valid-input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    width: 40px;
    pointer-events: none;

    .icon {
      margin: auto;
      color: var(--color-business);
    }
  }

}

.carbon-form__date-input input {
  padding-right: 10px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
