@import "../../../../shared/theme/variables";

[data-component="card"] {
  display: flex;
  font-family: var(--font-family-adelle);
  flex: 0 0 20rem;
  
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    max-width: calc(100vw - 60px);
    margin: 0 10px 25px !important;
    scroll-snap-align: center;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
.multiple-card__card {
    width: 20rem;
    margin-left:0.7rem;
    margin-right: 0.7rem;
    background-color: white;
    padding: 2.5px 2px 8px 2px;
    display: flex;
    flex-direction: column;
    border-radius: 40px;
    cursor: pointer
}
.multiple-card {
    padding-top: 10px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 15px;
    margin-right: 15px;
    background: #e2e2e2;
    color: black;
    border-radius: 40px;
    
}

.multiple-card__container{
    display:flex;
    border-radius: 40px;
    padding: 7px;
    background: #e2e2e2;
}
.cardm__offer-name {
    text-align: center;
}

.multiple-card__header {
  padding-bottom: 1rem;
    .card__offer {
    
        margin-left: 1.3rem;
    }
}

.cardm__header {
  text-align: center;
  min-height: 5.6rem;
  background: var(--color-text-white);
  color: var(--color-business);
  border-radius: 40px;
}

.cardm__row {
    padding: 0 1rem 1rem 1rem;;
    min-height: 16rem;
}

.cardm__offer {
  margin-bottom: 20px;
  font-size: 0.75rem;
  letter-spacing: 0.15px;
}

.cardm__offer-name {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.3px;
  font-family: 'Sage-Headline';
}

.cardm__offer-price {
  margin: 10px 0 2px;
  font-size: 1.125rem;
  line-height: 1;
}

.offer-price__amount-euros {
  margin-right: 0.125ch;
  font-size: 3rem;
  font-weight: 900;
}

.offer-price__amount-cents {
  font-size: 1.25rem;
}

.card__offer-info {
  margin-bottom: 25px;
  color: var(--color-text-light);
}

.cardm__offer-options {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-family: 'Sage-Ui';
    font-size: 16px;
    font-weight: 400;

  &:last-child {
    margin-bottom: 10px;
  }

  li {
    margin: 10px 0;
    display: flex;
    padding-top: 0.2em;
  }

  .icon {
    position: relative;
    top: 0.1em;
    margin: 0 15px 0 0;
    color: #007E45 !important;
    font-size: 1rem;
  }
}

.cardm__cta {
  margin-top: auto !important;
}

.cardm__row >.card__cta > .card_bouton > .button.medium
{
  border: 2px solid #007E45;
  background: #007E45;
  color: white;
  width: -webkit-fill-available;
}
.card__offer_SEE {
  margin-bottom: 20px;
  font-size: 0.75rem;
  letter-spacing: 0.15px;
  padding-left: 3rem;
}