*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

:disabled {
  pointer-events: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a:focus,
button:focus {
  outline: var(--focus-outline);
  box-shadow: none;
}

:-moz-focusring {
  outline: none;
  box-shadow: none;
}
