.actu{
    font-family: arial;
    text-align: center;

    @media screen and (max-width: 600px) {
        margin: 0px;
    }

    &__title{
        margin-bottom: inherit;
        margin-top: 34px;
        text-align: left;
        margin-left: 58px;
    }

    &__card{
        border: 1px gray solid;
        width: 96%;
        margin: 0 auto;
        margin-top: 20px;
        margin-left:42px;
        background-color:#F7FCFA !important;
        color: black;
        border-radius: 26px;
        font-family: var(--font-family-sage-text);
        @media screen and (max-width: 600px) {
            width: auto;
            height: auto;
        }

        &-subtitle{
            margin: 20px;
            font-size: xx-large;
        }

        &-description{
            margin: 20px;
            text-align: justify;
            font-size: larger;
        }
    }
}