.toast {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  height: max-content;
  width: 310px;
  flex-grow: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: white;
  padding: 0;
  box-shadow: rgba(0, 20, 29, 0.1) 0 10px 30px 0, rgba(0, 20, 29, 0.1) 0 30px 60px 0;
  font-family: "Adelle-Sans-Sage", Lato, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;

  &__text {
    padding: 10px;
  }

  &__icon {
    width: 30px;
    flex-shrink: 0;
  }

  &__close {
    padding: 0 10px;
    flex-shrink: 0;
    border: none;
    background: transparent;
    color: var(--color-text-light);
    outline: none;
    cursor: pointer;
  }
}

.toast.success {
  border: 1px solid #007E45;

  .toast__icon {
    background-color:#007E45;
  }
}

.toast.error {
  border: 1px solid rgb(199, 56, 79);

  .toast__icon {
    background-color: rgb(199, 56, 79);
  }
}
