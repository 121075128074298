@import "../../../../shared/theme/variables";

[data-component="card"] {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-adelle);
  flex: 0 0 20rem;
  align-items: center;
  border-radius: 40px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    max-width: calc(100vw - 60px);
    margin: 0 10px 25px !important;
    scroll-snap-align: center;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.card__header {
  min-height: 10.5rem;
  margin: 0 -24px;
  padding: 12px 46px 20px;
  background: var(--color-text-white);
  color: black;
  border-radius: 40px;
}

.card__row {
  min-height: 16rem;
}

.card__offer {
  margin-bottom: 20px;
  font-size: 0.75rem;
  letter-spacing: 0.15px;
}

.card__offer-name {
  margin-bottom: 0;
  font-size: 1.3rem;
  font-weight: normal;
  letter-spacing: 0.3px;
  font-family: 'Sage-Headline';
}

.card__offer-price {
  margin: 10px 0 2px;
  font-size: 1.125rem;
  line-height: 1;
}

.offer-price__amount-euros {
  margin-right: 0.125ch;
  font-size: 3rem;
  font-weight: 900;
}

.offer-price__amount-cents {
  font-size: 1.25rem;
}

.card_error_message {
  justify-content: space-between;
  color: var(--color-error) !important;
  font-weight: bold;
  font-size: 0.85rem;
  }
  
.card__offer-info {
  margin-bottom: 25px;
  color: var(--color-text-light);
}

.card__offer-options {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &:last-child {
    margin-bottom: 10px;
  }

  li {
    margin: 10px 0;
    display: flex;
    padding-top: 0.2em;
    font-family: 'Sage-Ui';
    font-size: 16px;
    font-weight: 400;
  }

  .icon {
    position: relative;
    top: 0.1em;
    margin: 0 15px 0 0;
    color: #007E45 !important;
    font-size: 1rem;
  }
}
[data-element="card-row"] {
  width: 100%;
}

.cart__description {
  width: 100%;
}

[data-element="card-row"]  > [data-element="card-column"] > .button.medium {
  border: 2px solid #007E45;
  background: #007E45;
  color: white;
  width: -webkit-fill-available;
}
.offerslist__cards-wrapper > [data-component="card"] {
    margin: 0 15px !important;
}