.heading {
  font-weight: 900;
  line-height: 48px;
  font-size: 40px;
}

.list__header-actions {
  display: flex;
  margin: 25px 0 30px;
}

.list__button-wrapper {
  display: flex;
  margin-left: auto;

  > *:not(:first-child) {
    margin-left: 20px;
  }
}

.list__select-wrapper{
  display: flex;

  > *:not(:first-child) {
    margin-left: 20px;
  }
}

.popover-button {
  display: flex;
  flex-direction: column;
}
