.popup{
    &__card{
        min-height: 230px;
        margin: 0 auto;
        margin-top: 20px;
        font-family: var(--font-family-sage-text);
        @media screen and (max-width: 600px) {
            width: auto;
            height: auto;
        }
    
        &-subtitle{
            margin: 20px;
            font-size: xx-large;
        }
    
        &-description{
            text-align: justify;
            font-size: larger;
        }
        &-close {
            bottom: 0;
            z-index: 1000;
            display: flex;
            justify-content: flex-end;
            margin: 8px 0px 8px 0px;            
            background: var(--color-bg-white);
          }
    }
   
}
