.select-interlocutor.full {
  display: flex;
  flex-flow: row nowrap;

  .select-interlocutor__select {
    flex-grow: 1;
  }

  .select-interlocutor__button {
    margin-left: 10px;
    width: 50px;
  }
}

.select-interlocutor.empty {
  display: flex;
  flex-flow: column nowrap;
}
