.loading {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;

  & > .spinner {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top-color: var(--color-business);
    width: 55px;
    height: 55px;
    animation: spin 0.8s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
