@import "../../../theme/variables";

.main-footer {
  background: var(--color-bg-grey);

  .page-wrapper--unauthenticated & {
    background: #000000;
  }
}

.main-footer__logo,
.main-footer__social {
  display: none;

  .page-wrapper--unauthenticated & {
    display: block;
    margin: 6px;
  }
}

.main-footer__logo {
  margin: 65px 70px 20px;
  width: 6.25rem;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    margin: 40px 20px 20px;
    width: 4rem;
  }
}

.main-footer__social {
  margin: 0 70px 133px;

  ul,
  li {
    display: flex;
    align-items: center;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    margin: 0 20px 60px;
  }
}

.main-footer__social-follow {
  margin-bottom: 10px;
  font-family: var(--font-family-adelle);
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.55);
}

.main-footer__social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  color: #fff;

  &:hover,
  &:focus {
    background: rgba(255, 255, 255, 0.12);
  }
}

.main-footer__links {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  .page-wrapper--unauthenticated & {
    border-top: 1px solid #303030;
    width: 100%;
  }

  ul,
  li {
    display: flex;
    align-items: center;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    &,
    ul {
      flex-direction: column;
    }
  }
}

.main-footer__link {
  margin: 0 10px;
  padding: 8px 10px;
  text-decoration: none;
  font-size: 0.875rem;

  &,
  &:visited {
    color: rgb(98, 98 ,98)
  }

  .page-wrapper--unauthenticated &,
  .page-wrapper--unauthenticated &::visited {
    color: #99adb6;
  }
}

.main-footer__copyright {
  color: #335c6d;

  .page-wrapper--unauthenticated & {
    color: #fff;
  }
}
