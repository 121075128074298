@import "../../theme/variables";

.modal-alert {
  background: var(--color-bg-grey);
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  width: 34rem;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: auto;
  overflow: hidden;
}

.modal-alert__header {
  display: flex;
  align-items: center;
  padding: 16px 24px 16px 32px;
  border-bottom: 1px solid var(--color-border-dark);

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    border: none;
  }
}

.modal-alert__title {
  margin: 0;
  font-family: var(--font-family-adelle);
  font-size: 1.25rem;
  font-weight: bold;
}

.modal-alert__icon {
  margin-right: 1rem;
  color: var(--color-warning);

  .icon {
    font-size: 1.5rem;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}
.modal-alert__icon-config {
  margin-right: 1rem;
  color: #007E45;

  .icon {
    font-size: 1.5rem;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.modal-alert__close {
  display: flex;
  margin-left: auto;
  padding: 8px;
  border: none;
  background: transparent;
  color: var(--color-text-light);
  cursor: pointer;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.modal-alert__content {
  padding: 16px 32px;
  color: var(--color-text-dark);
  font-size: 14px;
  line-height: 19.07px;
  font-weight: bold;
  overflow-y: auto;

  p:last-child {
    margin: 0;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    padding: 0 24px 30px;
    color: var(--color-text-light);
  }
}

.modal-alert__footer {
  .button.tertiary{
    color:#007E45;
    border: none;
    font-weight: 700;
    font-family: 'Sage-Ui';
    margin-inline: 15px;
    &:not(:disabled):hover {
      color: #006738 ;
      background: transparent;
    }
  }
    padding: 16px 32px;
  display: flex;
  justify-content: flex-end;
}
