@import "../../../theme/variables";

.list-header-mobile {
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: relative;
    margin: 0 -20px;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 40px;
      z-index: 100;
      opacity: 0;
      transition: opacity ease-out 0.05s;
      content: "";
    }

    &::before {
      left: 0;
      background: linear-gradient(to right, var(--color-bg-white), var(--color-bg-white) 50%, transparent);
    }

    &[data-scroll="left"]::before,
    &[data-scroll="both"]::before {
      opacity: 1;
      transition: opacity ease-in 0.15s;
    }

    &::after {
      right: 0;
      background: linear-gradient(to left, var(--color-bg-white), var(--color-bg-white) 50%, transparent);
    }

    &[data-scroll="right"]::after,
    &[data-scroll="both"]::after {
      opacity: 1;
      transition: opacity ease-in 0.15s;
    }
  }
}

.list-tabs {
  display: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: inline-flex;
    width: 100vw;
    overflow-x: auto;
    background: var(--color-bg-white);
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      height: 0;
      background: transparent;
    }
  }
}

.list-tabs__tab {
  position: relative;
  padding: 15px;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  white-space: nowrap;
  scroll-snap-align: center;

  &,
  &:visited {
    color: var(--color-text-light);
    text-decoration: none;
  }

  &.is-active {
    color: var(--color-business);
  }

  &.is-active::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    z-index: 1000;
    background: var(--color-business);
    content: "";
  }
}
