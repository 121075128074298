@import "../../../../../../shared/theme/variables";


.options-list .option {
 
  margin-bottom: 4px; 
  background: #FFFFFF; 
  border-radius: 24px;
}

.option {
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: minmax(0, 1fr) min-content;
  grid-template-areas:
    "name        action"
    "price       action"
    "description action";
  padding: 16px;
  background: rgba(#f2f4f5, 0.4);
  padding: 24px;


  &--selected {
    background: var(--color-bg-grey);
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    grid-template-rows: repeat(3, min-content);
    grid-template-columns: minmax(0, 1fr) min-content;
    grid-template-areas:
      "name        action"
      "price       action"
      "description description";
    margin-bottom: 0;
    padding-right: 8px;

    &--selected {
      position: relative;
      background: var(--color-business);
      color: var(--color-text-white);

      &+&::before {
        position: absolute;
        top: 0;
        left: 16px;
        right: 16px;
        height: 1px;
        background: rgba(255, 255, 255, 0.15);
        content: "";
      }
    }
  }
}

.option__name,
.option__price,
.option__description {
  font-family: var(--font-family-opensans);
  margin: 0;
}

.option__name {
  grid-area: name;
  margin-bottom: 2px;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.15px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1rem;
  }
}

.option__price {
  grid-area: price;
  margin-bottom: 2px;
  color: var(--color-business);
  letter-spacing: 0.15px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    color: inherit;
    font-size: 0.875rem;
  }
}

.option__description {
  grid-area: description;
  color: var(--color-text-light);
  font-size: 0.875rem;
  letter-spacing: 0.25px;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    font-size: 0.75rem;

    .option--selected & {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}

.option__action {
  grid-area: action;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.option__action_btn {
  grid-area: action;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  align-content: stretch;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// --------------------------
// Add / Remove button
// --------------------------
.option__add-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 1px 24px;
  background: none;
  border: none;
  color:#007E45;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--font-family-sage-ui);

  &:hover,
  &:focus {
    color: var(--color-business-hover);
  }

  .icon {
    display: none;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    align-self: center;
    padding: 1px 8px;

    .label {
      display: none;
    }

    .icon {
      display: flex;

      .option--selected & {
        color: var(--color-text-white);
      }
    }
  }
}