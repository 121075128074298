@import "../../../../../shared/theme/variables";

// --------------------------
// Section
// --------------------------
.preview__section {
  margin-bottom: 32px;
}

.preview__section-title {
  margin: 0 0 20px;
  color: var(--color-text-light);
  font-size: 0.75rem;
  text-transform: uppercase;
}

// --------------------------
// Form
// --------------------------
.preview__form {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -20px;
}

.preview__form-item {
  flex: 0 0 calc(50% - 40px);
  display: flex;
  flex-direction: column;
  margin: 10px 20px;

  &--fullwidth {
    flex-basis: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    flex-basis: calc(100% - 40px);
  }

  .preview-item__admin {
    margin-bottom: 10px;
  }
}

.preview__mandatory {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

// --------------------------
// Information tiles
// --------------------------
.preview__card {
  display: flex;
  margin: 2px 0;
  padding: 16px;
  min-height: 5em;
  background: var(--color-bg-grey);
}

.preview__card-icon {
  position: relative;
  top: 0.2em;
  margin-right: 24px;
  color: var(--color-text-gray);
  font-size: 1.5rem;
}

.preview__card-title {
  color: var(--color-text);
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 1.5;
}

.preview__card-description {
  color: var(--color-text-light);
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  line-height: 1.4;
}

.preview_Substitution-description {
  width: 100%;
  display: inline-block;
  //margin: 16px 0 24px;
  font-family: var(--font-family-adelle);
  color: var(--color-text-light);
  letter-spacing: -0.2px;
  line-height: 1.625rem;
}
.preview__Substitution_card { 
  margin: 2px 0;
  padding: 16px;
  min-height: 5em;
  background: var(--color-bg-grey);
}

.substitution__list {
  margin-bottom: 5px;
  //padding-left: 38px;
  font-size: 14px;
  // font-weight: 600;
  font-family: var(--font-family-adelle);
  color: var(--color-text-light);
  line-height: 21px;
  position: relative;
}
