.new-promo{
    min-height: 100vh;
}
.new-promo__popup{
    position: relative;
    z-index: 10;
    padding: 20px 20px;
    background: var(--color-bg-grey);
    font-size: 0.875rem;

}
.popup--full-page > .new-promo {
  padding: 6% 25% 25% 25%;
}
.new-promo__tabs {
  display: grid;
  grid-template-rows: min-content auto;
  grid-template-columns: max-content minmax(1, 1fr);
  grid-template-areas:
      "tablist tabpanel"
      ". tabpanel";
}
.list__header_big {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1.25fr) calc(var(--actions-count)* 40px) ;
}
.list-remise > .Collapsible > .Collapsible__contentOuter > .Collapsible__contentInner > .list > .list__header_big {
  display: grid;
  grid-template-columns: minmax(0, 1.7fr) minmax(0, 2fr) minmax(0, 1.55fr) minmax(0, 2fr) minmax(0, 1.55fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 2.8fr) calc(var(--actions-count)* 40px);
}
.list-remise__alert > .modal-alert {
  width: 70rem !important;
}