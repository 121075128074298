@import "../../../theme/variables";

// --------------------------
// Application main menu
// --------------------------
.main-menu-wrapper {
  position: fixed;
  top: 48px;
  bottom: 0;
  z-index: 10001;
  display: flex;
}

.main-menu-overlay {
  .main-menu-wrapper.is-open & {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background: var(--color-overlay);
  }
}

.main-menu {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 100%;
    z-index: 10000;
    width: 20rem;
    max-width: 85vw;
    background: var(--color-bg-white);
    transition: transform ease-in-out 0.2s;

    &.is-open {
      transform: translate3d(calc(100% - 1px), 0, 0);
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 20px 40px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

// --------------------------
// User information (mobile only)
// --------------------------
.main-menu__user {
  display: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: block;
    padding: 45px 15px 15px;
    background: var(--color-business);
    color: var(--color-text-white);
    font-family: var(--font-family-adelle);
  }
}

.main-menu__user-avatar {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  margin-bottom: 16px;
  background:#007E45;
  border: 1px solid var(--color-border-white);
  color: var(--color-text-white);
  font-size: 2rem;
}

.main-menu__user-name,
.main-menu__user-position {
  margin: 0;
}

.main-menu__user-name {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 15px 0 0;
  border: none;
  background: none;
  color: inherit;
  font-size: 1.25rem;

  .icon {
    margin-left: auto;
  }
}

.main-menu__user-position {
  font-size: 0.875rem;
}

// --------------------------
// Navigation links
// --------------------------
.main-nav {
  flex-grow: 1;
  color: var(--black-100); 
  background: var(--white); 
  padding: 0;
  list-style-type: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    color: var(--color-text);
    background: transparent;
    padding: 20px 0;
    overflow: auto;

    li:last-child {
      margin-bottom: 20px;
    }
  }
}

.main-nav__link {
  position: relative;
  z-index: 1000;
  display: flex;
  transition: box-shadow ease-out 0s;
  text-decoration: none;

  &,
  &:visited {
    color: inherit;
  }

  &:hover,
  &:focus {
    z-index: 1001;
    box-shadow: 0 10px 10px 0 rgba(0, 20, 29, 0.1), 0 5px 5px 0 rgba(0, 20, 29, 0.2);
    transition: box-shadow ease-in 0.05s 0.5s;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    align-items: center;
    padding: 5px 30px 5px 20px;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.main-nav__link-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background:  var(--white);

  img {
    width: 1rem;
    height: 1rem;
  }

  .main-nav__link:hover &,
  .main-nav__link:focus &,
  .main-nav__link.is-active & {
    background:  #D9E0E4 
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    width: auto;
    height: auto;
    background: transparent;

    .main-nav__link:hover &,
    .main-nav__link:focus &,
    .main-nav__link.is-active & {
      background: transparent;
    }

    .icon {
      font-size: 1.5rem;
      color: var(--color-text-gray);
    }
  }
}

.main-nav__link-label {
  position: absolute;
  z-index: -1;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 12.5rem;
  padding: 10px 20px;
  background:#D9E0E4;
  font-family: var(--font-family-adelle);
  font-size: 0.875rem;
  box-shadow: 0 10px 10px 0 rgba(0, 20, 29, 0.1), 0 5px 5px 0 rgba(0, 20, 29, 0.2);
  opacity: 0;
  transition: all ease-out 0s;

  .main-nav__link:hover &,
  .main-nav__link:focus & {
    transform: translate3d(100%, 0, 0);
    opacity: 1;
    transition: opacity ease-in 0.1s 0.5s;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: static;
    background: transparent;
    opacity: 1;
    box-shadow: none;

    .main-nav__link:hover &,
    .main-nav__link:focus & {
      transform: none;
    }
  }
}

.main-nav__link-chevron {
  display: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: inline-flex;
    margin-left: auto;
  }
}

// --------------------------
// Specific CSS for certain menu items
// --------------------------

.main-nav__hub,
.main-nav__support {
  display: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: block;
  }
}
