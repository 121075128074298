@import "../../theme/variables";

.popup-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  background-color: var(--color-overlay);
}

.popup {
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;

  &--full-page {
    > * {
      padding: 72px 43px 0;
    }

    @media screen and (max-width: $breakpoint-tablet-portrait) {
      > * {
        padding: 0;
      }
    }
  }
}

.popup-wrapper.small {
  .popup {
    width: 500px;
    height: 400px;
    flex-grow: 0;
    background-color: white;
  }
}

.popup-alert {
  justify-content: center;
  align-items: center;
}
