@import "../../../../shared/theme/variables";

.dashboard{
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  padding: 0 0 35px;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;  
  .dashboard-error{
    display: flex;
    margin-left: auto;
    color: gray;
    margin-top: auto;
    text-align: end;
    padding-right: 4%;
    padding-left: 4%;

    .icon  {
      width: 3em;
      height: 2.5em;
    }
  }


  .actu__subtitle {
    text-align: center;
    font-weight: lighter;
  }

  &__header{
    margin:auto;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    height: fit-content;
    width: 100%;
    margin-left: 43px;

    @media screen and (max-width: $breakpoint-small-screen) {
      grid-template-columns: 1fr;
      width: auto;
    }
    @media screen and (max-width: $breakpoint-phone) {
      grid-template-columns: 1fr;
      width: auto;
    }
  }
  &__headerSales{
    margin: auto;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-left: 48px;

    @media screen and (max-width: $breakpoint-small-screen) {
      grid-template-columns: 1fr;
      width: auto;
    }
    @media screen and (max-width: $breakpoint-phone) {
      grid-template-columns: 1fr;
      width: auto;
    }
  }
}
.dashboard-sales{
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  padding: 0 0 35px;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;  
  .dashboard-error{
    display: flex;
    margin-left: auto;
    color: gray;
    margin-top: auto;
    text-align: end;
    padding-right: 4%;
    padding-left: 4%;
    
    .icon  {
      width: 3em;
      height: 2.5em;
    }
  }


  .actu__subtitle {
    text-align: center;
    font-weight: lighter;
  }

  &__header{
    margin:auto;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    height: fit-content;
    width: 80rem;

    @media screen and (max-width: $breakpoint-small-screen) {
      grid-template-columns: 1fr;
      width: auto;
    }
    @media screen and (max-width: $breakpoint-phone) {
      grid-template-columns: 1fr;
      width: auto;
    }
  }
}

.WrapperHome {
  margin: auto;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fill-available;
  @media screen and (max-width: $breakpoint-small-screen) {
    width: auto;
    grid-template-columns: 1fr;
    gap: 0px;
  }
}
.WrapperTitle
{
  text-align: left;
  margin-left: 58px;
}
.gridDashboard{
  grid-column: 1 / span 2;
}