@import "../../../../shared/theme/variables";

.cgv-footer {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  padding: 10px 20px;
  border-top: 1px solid var(--color-border-light);
  background: var(--color-bg-grey);
}

.cgv-footer__policy {
  display: flex;
  align-items: baseline;
  margin: 0 30px 0 auto;
  font-size: 1.25rem;

  p {
    margin: 0;
  }

  a,
  a:visited {
    color: var(--color-business);
  }

  a:hover {
    color: var(--color-business-hover);
  }
}

.cgv-footer__check {
  margin: 0 1em 0 0;
  width: 20px;
  height: 20px;
}
