@import "../../../theme/variables";

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 0 40px 0 24px;

  background-color: #000000;
  box-shadow: inset 0px -1px 0px #303030;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: none;
  }
}

.envtitle {
  color: #fff;
  padding-left: 10px;
  font-family: var(--font-family-sage-ui);
  font-weight: normal;
}

.sage-100-logo {
  display: flex;
  align-items: center;
  margin: 0 auto 0 0;
  font-size: 1rem;

  .sage-logo {
    width: 50px;
    height: auto;
    margin: 2px 21px 0 0;
  }

  span {
    position: relative;
    color: #fff;
    font-family:var(--font-family-sage-ui);
    font-weight: 500;
  }

  span::before {
    position: absolute;
    top: 50%;
    left: -10px;
    height: 1.625rem;
    width: 1px;
    background: rgba(0, 0, 0, 0.3);
    transform: translateY(-50%) translateY(-1px);
    content: "";
  }
}

// --------------------------
// Header buttons
// --------------------------
.main-header__button-wrapper {
  align-self: stretch;
  position: relative;
  display: flex;
  margin-left: 8px;
}

.main-header__button {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  border: none;
  background: var(--color-bg-black);
  color: var(--white);

  &:hover,
  &.is-open {
    background: var(--color-bg-grey);
    color: var(--color-text);
  }
}

// --------------------------
// Help menu button
// --------------------------
.help-menu-toggle {
  color: var(--white);

  .icon {
    font-size: 1.375rem;
  }
}

// --------------------------
// User menu button
// --------------------------
.user-menu-toggle__avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  background: #000000; 
  color: var(--color-text-white);
  font-size: 0.875rem;
}

.user-menu-toggle__status {
  position: absolute;
  bottom: -3px;
  right: -3px;
  height: 8px;
  width: 8px;
  border: 1px solid var(--color-border-white);
  border-radius: 50%;

  &--online {
    background: var(--color-success);
  }

  &--offline {
    background: var(--color-error);
  }
}

// --------------------------
// Mobile header
// --------------------------
.main-header-mobile {
  display: none;

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10000;
    min-height: 3.5rem;
    padding: 8px 10px 8px 10px; 
    background: var(--color-business);
    color: var(--color-text-white);
  justify-content: space-between;
  }
}

.main-header-mobile__title {
  margin: 0 auto 0 20px;
  font-family: var(--font-family-adelle);
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: 0.25px;
}

.main-header-mobile__menu-button {
  padding: 8px;
  border: none;
  background: transparent;
  color: inherit;
  line-height: 1;
  cursor: pointer;

  .icon {
    font-size: 1.25rem;
  }
}

.main-header-mobile__cart {

  &,
  &:hover,
  &:focus {
    color: var(--color-text-white);
  }

  .icon {
    font-size: 1.5rem;
  }
}