.sepa__content-description {
  width: 100%;
  margin: 16px 0 0;
  font-family: var(--font-family-opensans);
  color: var(--color-text);
  font-size: 16px;
  line-height: 24px;
  text-align: left;

  .description-address {
    display: block;
    margin: 0 0 24px;
  }
  .description-mail {
    font-weight: bold;
  }
}
