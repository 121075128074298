.substitution__checkbox {
    margin-bottom: 20px;
    //padding-left: 38px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    position: relative;
}
.substitution__messages {
    width: 100%;
  //  display: inline-block;
    margin: 16px 0 24px;
    font-family: var(--font-family-adelle);
    //color: var(--color-text-light);
    letter-spacing: -0.2px;
    line-height: 1.625rem;
  }