// --------------------------
// User menu in desktop context
// (mobile context below)
// --------------------------
.user-menu {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  width: 24rem;
  background: var(--color-bg-white);
  box-shadow: 0 20px 40px 0 rgba(0, 20, 29, 0.1), 0 10px 20px 0 rgba(0, 20, 29, 0.2);
  font-size: 0.875rem;
  z-index: 1000;

  &.is-open {
    display: block;
  }

  .full-page-header__button {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

.user-menu__user {
  display: flex;
  padding: 16px 24px;
  box-shadow: inset 0 -1px 0 0 #d9e0e4;
  background:#F4F4F4 ; 
  color: var(--color-text-light);
}

.user-menu__avatar {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.875rem;
  height: 4.875rem;
  margin-right: 24px;
  background:#007E45;
  color: var(--color-text-white);
  font-size: 2rem;
}

.user-menu__username {
  margin-bottom: 0.125rem;
  font-size: 1rem;
  font-weight: bold;
  color: var(--color-text);

  span {
    text-transform: uppercase;
  }
}

.user-menu__email,
.user-menu__status {
  margin-bottom: 0;
  font-weight: 500;
}

.user-menu__role,
.user-menu__status {
  font-size: 0.75rem;
}

.user-menu__role {
  font-style: italic;
  margin-bottom: 0.375rem;
}

.user-menu__status {
  display: flex;
  align-items: center;
}

.user-menu__status-indicator {
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 6px;
  border: 1px solid var(--color-border-white);
  border-radius: 50%;

  &--online {
    background: var(--color-success);
  }

  &--offline {
    background: var(--color-error);
  }
}

.user-menu__link {
  display: flex;
  align-items: center;
  height: 3rem;
  width: 100%;
  border: none;
  background: none;
  font-size: inherit;

  &,
  &:visited {
    padding: 0 24px;
    color: inherit;
    text-decoration: none;
    font-weight: 500;
  }

  &:hover {
    background: #007E45; 
    color: var(--color-text-white);
  }

  &--separator {
    border-top: 1px solid var(--color-border-dark);
  }
}

.user-menu__link-icon {
  display: none;
}

.user-menu__link-chevron {
  display: none;
}

// --------------------------
// User menu in mobile context
// --------------------------
.main-menu {
  .user-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color-bg-grey);
    box-shadow: none;
  }

  .user-menu__user {
    display: flex;
    align-items: center;
    min-height: 3.5rem;
    padding: 8px 10px;
    background: var(--color-business);
    color: var(--color-text-white);
    box-shadow: none;
  }

  .full-page-header__button {
    display: block;
  }

  .user-menu__username {
    margin: 0 auto 0 20px;
    color: inherit;
    font-family: var(--font-family-adelle);
    font-size: 1.25rem;
    font-weight: normal;
    letter-spacing: 0.25px;

    span {
      text-transform: none;
    }
  }

  .user-menu__avatar,
  .user-menu__email,
  .user-menu__role,
  .user-menu__status {
    display: none;
  }

  .user-menu__link {
    height: 5rem;
    padding: 5px 30px 5px 20px;

    &--separator {
      border: none;
    }

    &:hover {
      background: initial;
      color: initial;
    }
  }

  .user-menu__link-icon {
    display: flex;

    .icon {
      font-size: 1.5rem;
      color: var(--color-text-gray);
    }
  }

  .user-menu__link-label {
    padding: 10px 20px;
    font-family: var(--font-family-adelle);
    font-size: 0.875rem;

    em,
    strong {
      font-weight: normal;
      font-style: normal;
    }
  }

  .user-menu__link-chevron {
    display: inline-flex;
    margin-left: auto;
  }
}
