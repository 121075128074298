@import "../../../../shared/theme/variables";

.list-proposals-page {
  .heading,
  .description,
  .list__select-wrapper,
  .popover-button > button {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      display: none;
    }

    .establishment-filter {
      width: 15rem;
    }
  }

  .list__header-actions {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      margin: 0;
    }
  }

  .list {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      padding: 24px 0 24px;
    }
  }
  background-color: #F4F4F4 !important ;
  // .list__row {
  //   grid-template-columns:
  //   minmax(0, 1.75fr)
  //   minmax(0, 1.5fr)
  //   minmax(0, 3fr)
  //   minmax(0, 1.5fr)
  //   minmax(0, 3fr)
  //   minmax(0, 2fr)
  //   minmax(0, 1.5fr)
  //   minmax(0, 1.5fr)
  //   minmax(0, 1.5fr)
  //   minmax(0, 1.25fr)
  //   minmax(0, 2fr)
  //   calc(var(--actions-count) * 40px);

  //   .list__reference {
  //     font-weight: bold;
  //   }
  // }
  .list__row_enduser {
    .list__reference {
      font-weight: bold;
    }
  }
  .list__header_big {
    display: grid;
    grid-template-columns:
    minmax(0, 1.75fr)
    minmax(0, 1.5fr)
    minmax(0, 3fr)
    minmax(0, 1.5fr)
    minmax(0, 3fr)
    minmax(0, 2fr)
    minmax(0, 1.5fr)
    minmax(0, 1.5fr)
    minmax(0, 1.5fr)
    minmax(0, 1.25fr)
    minmax(0, 1.6fr)

    calc(var(--actions-count) * 40px);
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      display: none;
    }
  }
  
  .list__row {
    grid-template-columns:
    minmax(0, 1.75fr)
    minmax(0, 1.5fr)
    minmax(0, 3fr)
    minmax(0, 1.5fr)
    minmax(0, 3fr)
    minmax(0, 2fr)
    minmax(0, 1.5fr)
    minmax(0, 1.5fr)
    minmax(0, 1.5fr)
    minmax(0, 1.25fr)
    minmax(0, 1.6fr)
    calc(var(--actions-count) * 40px);
  
    .list__status {
      justify-content: center;
    }

    .list__pay.success a,
    .list__unblock button {
      color: var(--color-success);
    }

    .list__refuse button,
    .list__block button {
      color: var(--color-error);
    }

    .list__probability,
    .list__created,
    .list__validity {
      justify-content: center;
    }
    .list__total,
    .list__created,
    .list__validity {
      text-align: center;
    }

    .list__total {
      justify-content: flex-end;
    }

    @media screen and (max-width: $breakpoint-tablet-portrait) {
      grid-template-rows: repeat(4, min-content);
      grid-template-columns: 1fr minmax(0, 1fr) repeat(2, min-content);
      grid-template-areas:
        "status     status     actions    actions"
        "clientName clientName clientName clientName"
        "reference  reference  validity   total";
      column-gap: 10px;
      align-items: flex-start;
      color: var(--color-text-light);

      .list__client,
      .list__created,
      .list__probability,
      .list__clientReference,
      .list__partnerCode,
      .list__partnerName {
        display: none;
      }

      .list__validity {
        grid-area: validity;
        justify-content: flex-end;
      }

      .list__status {
        grid-area: status;
        justify-content: flex-start;
      }

      .list__clientName {
        grid-area: clientName;
        font-size: 1rem;
        font-weight: normal;
        color: var(--color-text);
      }

      .list__reference {
        grid-area: reference;
        font-weight: normal;
      }

      .list__total {
        grid-area: total;
        justify-content: flex-end;
      }

      .list__actions {
        grid-area: actions;
      }
    }
  }
  }


