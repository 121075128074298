@import "../../theme/variables";

// --------------------------
// Contenaur de la page FAQ
// --------------------------
.faq-wrapper {
  padding-right: 60px;

  .description {
    margin-bottom: 35px;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    padding-right: 20px;

    .heading {
      display: none;
    }

    .description {
      margin: 16px 0 24px;
    }
  }
}

.faq__section-title {
  margin-top: 2rem;
  font-size: 1.25rem;
}

// --------------------------
// Element de FAQ
// --------------------------
.faq__item {
  transition: background ease-in-out 0.2s;
  border-bottom: 1px solid var(--color-border-medium);

  &.is-open {
    background: var(--color-bg-white);
    border: none;
  }
}

.faq__question {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 128px 12px 48px;
  border: none;
  background: transparent;
  color: var(--color-text);
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: left;

  &:hover {
    color: var(--color-business);
  }

  .icon {
    position: absolute;
    left: 20px;
    font-size: 0.75rem;
    transition: all ease-in-out 0.2s;
  }

  &[aria-expanded="true"] .icon {
    transform: rotate(90deg);
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    padding: 8px 10px 12px 24px;

    .icon {
      left: 4px;
    }
  }
}

.faq__answer {
  margin: 0;
  padding: 0 128px 24px 48px;
  color: var(--color-text-dark);
  font-size: 0.875rem;

  &.is-hidden {
    display: none;
  }

  p:last-child {
    margin: 0;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    padding: 8px 10px 12px 24px;
  }
}
