.contact-form__item {
  margin: 0.75em 0;
  display: flex;
  flex-direction: column;
}

.contact-form__label {
  margin: 0.5em 0;
}

.contact-form__mandatory {
  font-weight: normal;
  font-size: 0.75rem;
}
