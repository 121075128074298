@import "../../../../shared/theme/variables";

.step-navigation {
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-areas: "previous steps next";
  column-gap: 24px;
  align-items: center;
  min-height: 5.375rem;
  margin: 0 -20px;
  padding: 10px 20px;
  border-top: 1px solid var(--color-border-light);
  background: var(--color-bg-white);
}

.step-navigation__previous,
.step-navigation__next {
  margin: 0 !important;

  .icon {
    display: none;
  }
}

.step-navigation__previous {
  grid-area: previous;
  justify-self: flex-start;
}

.step-navigation__next {
  grid-area: next;
  justify-self: flex-end;
}

.step-navigation__action {
  grid-area: next;
  justify-self: flex-end;
  display: flex;
  
  .step-navigation__loader {
    display: none;
  }
}

// --------------------------
// Custom step sequence
// --------------------------
.step-sequence {
  grid-area: steps;
  display: flex;
  min-width: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.step-sequence__step {
  flex: 0 1 auto;
  display: flex;
  align-items: center;
  min-width: 0;
  font-weight: bold;
  color: var(--color-text-light);

  &::before,
  &::after {
    flex: 0 1 auto;
    height: 1px;
    width: 100vw;
    max-width: 40px;
    content: "";
    background: var(--color-text-light);
  }

  &:first-child {
    margin-left: auto;

    &::before {
      display: none;
    }
  }

  &:last-child {
    margin-right: auto;

    &::after {
      display: none;
    }
  }
}

.step-sequence__step-marker,
.step-sequence__step-number {
  margin: 0 8px 0 16px;
  width: 1rem;
}

.step-sequence__step-marker {
  display: none;
}

.step-sequence__step-label {
  margin-right: 16px;
}

// Step current
[data-status="current"] {
  color: var(--color-text-dark);

  &::before {
    background: var(--color-business);
  }

  &::after {
    background: var(--color-text-light);
  }
}

// Step complete
[data-status="complete"] {
  color: var(--color-business);

  .step-sequence__step-marker {
    display: block;
    font-size: 1rem;
  }

  .step-sequence__step-number {
    display: none;
  }

  &::before,
  &::after {
    background: var(--color-business);
  }
}

// --------------------------
// Media queries
// They are exceptionally condensed here to facilitate comprehension, due to the amount of changes between devices
// --------------------------
@media screen and (max-width: $breakpoint-tablet-portrait) {
  .step-navigation {
    grid-template-rows: 3.5rem 4.625rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas:
      "previous next"
      "steps    steps";
    column-gap: 0;
    padding: 0;
    margin: 0 -10px;

    .step-navigation__previous {
      .icon {
        margin-right: 8px;
      }
    }

    .step-navigation__next {
      .icon {
        margin-left: 8px;
      }
    }

    .step-navigation__previous,
    .step-navigation__next {
      padding: 0;
      background: transparent !important;
      border: none;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      [data-element="main-text"] {
        display: flex;
        align-items: center;
      }

      .icon {
        display: flex;
      }

      &:not(:disabled),
      &:not(:disabled):hover {
        color: var(--color-text);

        .icon {
          color: var(--color-business);
        }
      }
    }
  }

  .step-sequence {
    align-self: stretch;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    background: var(--color-bg-grey);
  }

  .step-sequence__step {
    flex: 1 0 0;
    display: grid;
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      "before after"
      "label  label";

    &::before,
    &::after {
      width: 100%;
      max-width: initial;
      background: #93d4fb;
    }

    &::before {
      grid-area: before;
    }

    &::after {
      grid-area: after;
    }
  }

  .step-sequence__step-marker,
  .step-sequence__step-number {
    display: none !important;
  }

  .step-sequence__step-label {
    grid-area: label;
    position: relative;
    justify-self: center;
    margin: 0;
    padding-top: 10px;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--color-text-lighter);

    &::before {
      position: absolute;
      top: 0;
      left: 50%;
      height: 12px;
      width: 12px;
      background: #93d4fb;
      border-radius: 100%;
      content: "";
      transform: translate3d(-50%, -50%, 0);
    }
  }

  // Step current
  [data-status="current"],
  [data-status="complete"] {
    color: var(--color-business);

    &::before {
      background: var(--color-business);
    }

    .step-sequence__step-label {
      color: var(--color-business);
    }

    .step-sequence__step-label::before {
      background: var(--color-business);
    }
  }

  [data-status="complete"] {
    &::after {
      background: var(--color-business);
    }
  }

  .step-navigation__action {
    grid-area: steps;
    align-self: stretch;
    justify-self: initial;
    align-items: center;
    justify-content: space-between;
    margin: -1px 0;
    padding: 0 16px;
    background: var(--color-bg-white);
    z-index: 100;

    .step-navigation__order,
    .step-navigation__save {
      max-width: calc(50% - 10px);
      margin: 0;
      border: 1px solid var(--color-border-dark);
      background: var(--color-bg-white);
      text-transform: uppercase;

      &--sepa {
        max-width: 100%;
      }
    }

    .step-navigation__order:not(:disabled),
    .step-navigation__order:not(:disabled):hover {
      color: var(--color-business);
    }

    .step-navigation__save:not(:disabled),
    .step-navigation__save:not(:disabled):hover {
      color: var(--color-business);
    }
  }

  .step-navigation__sepa {
    grid-template-areas:
      "next next"
      "steps steps";

    .step-navigation__action {
      grid-area: next;
      margin: auto;

      .step-navigation__loader {
        display: grid;
      }
    }
  }
}
