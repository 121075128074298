@import "../../../../../../shared/theme/variables";

.search-client__wrapper {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  * {
    margin-right: 25px;
  }

  & .search-menu__actions,
  & .search-menu__item {
    margin: 0;
  }
}
