.pill__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pill {
  display: inline-block;
  border: 2px solid var(--color-business);
  min-height: 20px;
  font-family: var(--font-family-opensans);
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.7px;
  padding: 1px 12px;
  border-radius: 20px;
}
