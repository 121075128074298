@import "../../../../shared/theme/variables";

.attachment-list__description {
  font-weight: normal;
}

.attachment-list__alert {
  .modal-alert {
    width: 76rem;
  }
}

.attachment-form__item{
  margin: 0.75em 0;
  display: flex;
  flex-direction: column;
}

.attachment-form__label{
  margin: 0.5em 0;
  font-weight: normal;
}
.attachment-list__table {
  margin-top: 30px;

  .list__header,
  .list__row {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 60px 15ch minmax(0, 1fr) 20ch 20ch minmax(0, 1fr);
    grid-template-areas: "radio code name siret zipcode city";
  }

  .list__row {
    font-weight: normal;
  }

  .list__tiers {
    grid-area: radio;
    justify-self: center;
  }

  .list__code {
    grid-area: code;
    font-weight: bold;
  }

  .list__name {
    grid-area: name;
  }

  .list__siret {
    grid-area: siret;
  }

  .list__zipcode {
    grid-area: zipcode;
  }

  .list__city {
    grid-area: city;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    .list__header,
    .list__row {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: 50px min-content minmax(0, 1fr);
      grid-template-areas:
        "radio code    code"
        "radio name    name"
        "radio siret   siret"
        "radio zipcode city";
      grid-gap: 0 6px;
      padding: 6px;
    }
  }
}
