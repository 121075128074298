@import "../../theme/variables";

.search-menu {
  display: none;
  position: absolute;
  width: 24rem;
  padding: 40px 24px;
  background: var(--color-bg-white);
  box-shadow: 0 20px 40px 0 rgba(0, 20, 29, 0.1), 0 10px 20px 0 rgba(0, 20, 29, 0.2);
  font-size: 0.875rem;
  z-index: 1000;
  min-height: max-content;
  transform: translate3d(-216px, 42px, 0);

  &.is-open {
    display: block;
  }

  .full-page-header {
    display: none;
    margin: 0 -16px 24px;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    width: auto;
    padding: 0 16px 24px;
    transform: none;

    .full-page-header {
      display: flex;
    }
  }
}

.search-menu__fieldset {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em -0.5em 1em;
}

.search-menu__checkbox {
  margin: 0.5em;
}

.search-menu__item {
  margin-bottom: 24px;
}

.search-menu__actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px -0.5em -0.5em;
}

.search-menu__button {
  margin: 0.5em;
}
.popup-menu{
  display: none;
  position: absolute;
  width: 40rem;
  padding: 24px 20px 10px 20px;
  background: var(--color-bg-white);
  box-shadow: 0 20px 40px 0 rgba(0, 20, 29, 0.1), 0 10px 20px 0 rgba(0, 20, 29, 0.2);
  font-size: 1.2rem;
  z-index: 1000;
  min-height: max-content;
  transform: translate3d(-216px, 42px, 0);

  &.is-open {
    display: block;
  }

  .full-page-header {
    display: none;
    margin: 0 -16px 24px;
  }

  @media screen and (max-width: $breakpoint-tablet-portrait) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    width: auto;
    padding: 0 16px 24px;
    transform: none;

    .full-page-header {
      display: flex;
    }
  }
}
