@import "../../theme/variables";

.homepage {
  padding: 0 0 35px;
  position: relative;
  overflow: hidden;

  .actu__subtitle {
    text-align: center;
    font-weight: lighter;
  }

  &__header{
    justify-content: center;
    height: fit-content;
    gap: 60px;

    @media screen and (max-width: $breakpoint-small-screen) {
      flex-direction: column;
      gap: 0px;
    }
  }
}