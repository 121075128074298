@import "../../../../shared/theme/variables";

.list-clients-page {
  .heading,
  .description,
  .list__select-wrapper,
  .popover-button > button {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      display: none;
    }

    .establishment-filter {
      width: 15rem;
    }
  }

  .list__header-actions {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      margin: 0;
    }
  }

  .list {
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      padding: 24px 0 24px;
    }
  }

  .list__row {
    .list__code {
      font-weight: bold;
    }
  }

  .list__header,
  .list__row {
    grid-template-columns:
      minmax(0, 1fr)
      minmax(0, 2fr)
      minmax(0, 1.25fr)
      minmax(0, 100px)
      minmax(0, 2fr)
      minmax(0, 2fr)
      minmax(0, 2fr)
      calc(var(--actions-count) * 40px);

    @media screen and (max-width: $breakpoint-tablet-portrait) {
      grid-template-rows: repeat(3, min-content);
      grid-template-columns: min-content minmax(0, 1fr) max-content;
      grid-template-areas:
        "name    name actions"
        "code    code code"
        "zipCode city city";
      // align-items: flex-start;
      color: var(--color-text-light);

      .list__email,
      .list__phone,
      .list__reference {
        display: none;
      }

      .list__code {
        grid-area: code;
        font-weight: normal;
      }

      .list__name {
        grid-area: name;
        font-size: 1rem;
        font-weight: normal;
        color: var(--color-text);
      }

      .list__zipCode {
        grid-area: zipCode;
      }

      .list__city {
        grid-area: city;
        display: flex;

        &::before {
          margin: 0 0.75ch;
          content: "-";
        }
      }

      .list__actions {
        grid-area: actions;
      }
    }
  }
}
