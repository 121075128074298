@import "../../../../shared/theme/variables";

.borderdash
{
    width: 92%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 40px;
  
    @media screen and (max-width: $breakpoint-phone) {
      width: auto;
    }
}
.dashboard__lastUpdate{
  text-align: center;
  justify-content: flex-end;
  padding-top: 8px;
  vertical-align: middle;
  margin: 0.5em 2em;
  font-size: small;
  color: grey;
  max-height: 1.5rem;
}

.textdeco {
  text-decoration: none;
}

.total__header{
  margin: 1.25rem;
  text-align: left;
  font-size: x-large;  
  .total__header-count{
    width: 100px;
    display: inline-block;
    text-align: left;
  }
}

.total__title{
  margin: 1.25rem; 
}

.margin__total{
  margin-top: 20px;
  font-size: 15px;

}


.details__dashboard {
  top: 5rem;
  right: 0;
  width: 6.87rem;
  height: 6.25rem;
  text-align: center;
}

.dashboard__date{
  font-size: initial;
  margin-left: 5.625rem;
  top: 5rem;
  right: 0;
  width: 3.75rem;
  height: 6.25rem;
  text-align: center;
}

.total-title{
  font-weight: bold;
  color: rgba(0, 129, 70, 1);
}
.list__value, .list__number{

  justify-content: flex-end;
  padding-right: 2rem;
}
.list__value button{
  font-weight: bold;
}

.borderdash > .list > .list__header >.list__header-column
{
  box-shadow: inset 0px -1px 0px  #D9D9D9 !important;
}
.borderdash > .total__header .dashbord_header> .list > .list__header >.list__row
 {
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
.borderdash > .list > .list__row > .list__text-button button{
  text-decoration: none;
  color: black;
}
.borderdash > .list {
  padding: 0px 20px 0px 20px;
  min-height: 280px;

}