@import "../../../../../shared/theme/variables";

.step-payment {
  @media screen and (max-width: $breakpoint-tablet-portrait) {
    .loading {
      display: none;
    }
  }
}

.sepa-loading__message {
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}

.sepa-loading__redirect-img {
  img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    @media screen and (max-width: $breakpoint-tablet-portrait) {
      width: 100%;
    }
  }
}
